import React, { useEffect, useState } from "react"
import tw, {css} from "twin.macro"
import { Helmet } from "react-helmet"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import breakpoints from "../../styles/breakpoints"

const Ad = () => {

	const bpDesktop = breakpoints.find(bp => bp.md).md
	const bpMobile = breakpoints.find(bp => bp.sm).sm
	const [isDesktop, setIsDesktop] = useState(false)
	const [isMobile, setIsMobile] = useState(false)
	const { width } = useWindowDimensions()

	useEffect(() => {
		setIsDesktop(width >= bpDesktop)
		setIsMobile(width < bpMobile)
		
	}, [width])

	return (<>
		<Helmet>
			<script src="https://hb.vntsm.com/v3/live/ad-manager.min.js" type="text/javascript" data-site-id="6357fe75b1abda701427d92a" data-mode="scan" async></script>
		</Helmet>
		<div className="vm-placement" data-id="6373750d98fb531abee85ae5" style={{display:'none'}}></div>
		<div tw="absolute w-full min-h-screen flex bg-green pt-14 flex flex-col">
			{isDesktop && <div tw="flex items-center justify-center py-8" css={{height:"314px"}}>
				<div className="vm-placement" data-id="637370bb889c301e8e7ad53d"></div>
			</div>}
			{isMobile && <div tw="flex items-center justify-center py-4" css={{height:"314px"}}>
				<div className="vm-placement" data-id="6373760b98fb531abee85ae9"></div>
			</div>}
			<div tw="flex-grow h-104 grid grid-cols-2">
				<div tw="col-span-1"></div>
				<div tw="col-span-1" css={{
					paddingLeft:"208px"
				}}><div tw="px-8 flex flex-col items-center justify-center h-full">
						<div className="vm-placement" id="vm-av" data-format="isvideo"></div>
					</div>
				</div>
			</div>
			{isDesktop && <div tw="flex items-center justify-center py-8" css={{height:"314px"}}>
				<div className="vm-placement" data-id="637370d898fb531abee85aab"></div>
			</div>}
		</div>
	</>)
}

export default Ad